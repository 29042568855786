import React, { useState } from 'react';
import { navigate } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const Form = ({ product, app_type, submitUrl }) => {
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      product,
      app_type,
      email,
      feedback,
    };

    try {
      const response = await fetch(submitUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Feedback submitted successfully');
        setEmail('');
        setFeedback('');
      } else {
        alert('Error submitting feedback');
      }
    } catch (error) {
      alert('Error submitting feedback');
    }
  };

  return (
    <div>
        <div className="max-w-lg mx-auto p-6 mt-2 bg-white shadow-md rounded-md">
            <div className="flex flex-col gap-[12px] mb-6">
                <h1 className="text-[#141414] text-[24px] font-semibold tracking-[-0.8px]">
                Sorry, {product} is not quite ready yet.
                </h1>
                <p className="text-[#141414] text-[18px] font-medium leading-[150%]">
                We’re just putting the finishing touches to the app and will be releasing it in a few weeks. Feel free to leave your email below so we can ping you when the app is live.
                </p>
                <p className="text-[#141414] text-[18px] font-medium leading-[150%]">
                If you can, leave some feedback about what you’re most excited to learn about or which features you’d most want to see.
                </p>
                <p className="text-[#141414] text-[18px] font-medium leading-[150%]">
                See you very soon 👋
                </p>
            </div>
            
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                <label htmlFor="email" className="block text-gray-700 font-medium mb-2">Email:</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Your email (optional)"
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
                </div>
                <div>
                <label htmlFor="feedback" className="block text-gray-700 font-medium mb-2">Feedback:</label>
                <textarea
                    id="feedback"
                    name="feedback"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Your feedback (optional)"
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
                </div>
                <button type="submit" className="w-full bg-black text-white p-2 rounded-md hover:bg-blue-600">
                Submit
                </button>
            </form>
        </div>
    </div>
    
  );
};

export default Form;
