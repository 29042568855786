import React from 'react';
import Form from '../components/form';
import Layout from '../components/layout';

const LearnInterest = () => {
  const product = 'Leo';
  const app_type = 'soft_cta';
  const submitUrl = 'https://submit-form.com/MKYKSK9sQ';

  return (
    <Layout>
        <div>
            <Form product={product} app_type={app_type} submitUrl={submitUrl} />
        </div>
    </Layout>
  );
};

export default LearnInterest;
